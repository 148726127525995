import React from 'react';
import ImageGallery from '../../../shared/image/image-gallery/ImageGallery';
import './GrayPointPopup.css';

function getTrashArea(enumTrashType) {
   switch (enumTrashType) {
      case "TYPE_1": return "Nhỏ hơn 5 mét vuông";
      case "TYPE_2": return "5-10 mét vuông";
      case "TYPE_3": return "10-20 mét vuông";
      case "TYPE_4": return "20-50 mét vuông";
      case "TYPE_5": return "Lớn hơn 50 mét vuông";
      default: return null;
   }
};

const GrayPointPopup = (props) => {
   const detail = props.detail;
   const trashDetail = detail.grayPointReportReqDetail ? detail.grayPointReportReqDetail : null;
   const infoWindowStyle = {
      position: 'absolute',
      bottom: 15,
      left: '-45px',
      width: '300px',
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 10,
      fontSize: 14,
      zIndex: 100,
      border: '1px solid transparent',
      borderRadius: '10px'
   };

   return (
      <>
         <div style={infoWindowStyle}>
            <ImageGallery images={detail.imageUrlList}>
            </ImageGallery>
            <br></br>
            <h5 className="name">{detail.name}</h5>
            <div className="address">{detail.address}</div>

            {trashDetail != null &&
               <>
                  <div className="trash__areatype">Diện tích: {getTrashArea(trashDetail.areaType)}</div>
                  {trashDetail.trashContainerType != null && <div className="trash__containertype">Trữ lượng: {trashDetail.trashContainerType.name}</div>}
                  <div className="trash__trashtype">Loại rác:
                     <ul>
                        {trashDetail.trashTypes.map((type, index) =>
                           <li key={index}>{type.name}</li>
                        )}
                     </ul>
                  </div>
               </>
            }
            {(detail.description != null && detail.description !== '') && <div className="description" dangerouslySetInnerHTML={{ __html: detail.description }}></div>}
         </div>
      </>
   )
}

export default GrayPointPopup
