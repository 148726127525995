import React, { useEffect, useState } from 'react';
import {
   BrowserRouter as Router,
   Switch,
   Route,
 } from "react-router-dom";
import InstallAppPage from '../../install-app/InstallAppPage';
import { axios } from '../../axios/axios';
import CustomMap from '../../map/CustomMap';
import './DesktopContent.css';
import DesktopHeader from './DesktopHeader';

const DesktopContent = () => {
   const [items, setItems] = useState([]);
   const getData = async () => {
      const resp = await axios
         .get(`/api/map/point?pointType=GRAY`)
         .catch(err => console.error(err))

      if (resp && resp.data) {
         let newItems = resp.data.data;
         setItems([...newItems]);
      }
   };

   useEffect(() => {
      getData();
   }, [])

   const screenProps = {
      width: `${window.innerWidth}px`,
      height: `${window.innerHeight - 65}px`,
      zoom: window.innerHeight < 700 ? 5.5 : 6,
   }

   return (
      <>
         <Router>
            <DesktopHeader />
            <Switch>
               <Route exact path="/">
                  <div className="map">
                     <div className="center">
                        <CustomMap
                           items={items}
                           scrollWheelZoom={true}
                           width={screenProps.width}
                           height={screenProps.height}
                           zoom={screenProps.zoom}
                        />
                     </div>
                  </div>
               </Route>
               <Route path="/cai-dat">
                  <InstallAppPage></InstallAppPage>
               </Route>
            </Switch>
         </Router>
      </>
   )
}

export default DesktopContent

// react hook axios turorial
// https://www.youtube.com/watch?v=AirWT_XpEpM