import GoogleMapReact from 'google-map-react';
import React, { useRef, useState } from 'react';
import useSupercluster from 'use-supercluster';
import GrayPointMarker from './custom-markers/GrayPointMarker';
import './CustomMap.css';

const Marker = ({ children }) => children;

const CustomMap = (props) => {
   const containerStyle = {
      width: props.width ? props.width : '1024px',
      height: props.height ? props.height : '900px'
   };
   const VIETNAM_CENTER = {
      lat: 15.961,
      lng: 109.424
   };
   const defaultZoom = props.zoom ? props.zoom : 6.2;
   const center = props.center ? props.center : VIETNAM_CENTER;
   const mapRef = useRef();
   const points = props.items.map(item => ({
      type: "Feature",
      properties: { cluster: false, pointId: item.id, status: item.status },
      geometry: {
         type: item.type,
         coordinates: [
            item.longitude,
            item.latitude
         ]
      }
   }));

   const [bounds, setBounds] = useState(null);
   const [zoom, setZoom] = useState(10);
   const { clusters, supercluster } = useSupercluster({
      points,
      bounds,
      zoom,
      options: { radius: 75, maxZoom: 20 }
   });

   return (
      /* Google Map Cluster:
      *  https://www.leighhalliday.com/google-maps-clustering
      *  please read it for information about this approach to handle cluster.
      */
      <div style={containerStyle} >
         <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API }}
            defaultCenter={center}
            defaultZoom={defaultZoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => {
               mapRef.current = map;
            }}
            onChange={({ zoom, bounds }) => {
               setZoom(zoom);
               setBounds([
                  bounds.nw.lng,
                  bounds.se.lat,
                  bounds.se.lng,
                  bounds.nw.lat
               ]);
            }}
         >
            {clusters.map(cluster => {
               const [longitude, latitude] = cluster.geometry.coordinates;
               const {
                  cluster: isCluster,
                  point_count: pointCount
               } = cluster.properties;

               if (isCluster) {
                  return (
                     <Marker
                        key={`cluster-${cluster.id}`}
                        lat={latitude}
                        lng={longitude}
                     >
                        <div
                           className="cluster-marker"
                           style={{
                              width: `${10 + (pointCount / points.length) * 30}px`,
                              height: `${10 + (pointCount / points.length) * 30}px`,
                              position: 'absolute',
                              top: '-15px',
                              left: '-15px',
                           }}
                           onClick={() => {
                              const expansionZoom = Math.min(
                                 supercluster.getClusterExpansionZoom(cluster.id),
                                 20
                              );
                              mapRef.current.setZoom(expansionZoom);
                              mapRef.current.panTo({ lat: latitude, lng: longitude });
                           }}
                        >
                           {pointCount}
                        </div>
                     </Marker>
                  );
               } else {
                  return (
                     <GrayPointMarker
                        key={`point-${cluster.properties.pointId}`}
                        lat={latitude}
                        lng={longitude}
                        id={cluster.properties.pointId}
                     />
                  );
               }
            })}
         </GoogleMapReact>
      </div >
   );
}

export default CustomMap