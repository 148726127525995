import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const ImageGallery = (props) => {
   const imageHeight = props.imageHeight ? props.imageHeight : "200px";
   const style = {
      textAlign: "center",
   }

   return (
      <div className="slide-container">
         {props.images.length > 0 &&
            <Slide autoplay={false}>
               {props.images.map((image, index) =>
                  <div className="each-slide" key={index} style={style}>
                     <img
                        key={index}
                        id={"image-" + index}
                        className="image"
                        src={image}
                        alt={"image " + index}
                        height={imageHeight}
                        width="auto"
                     />
                     {/* --TODO LATER-- image tooltip show full-size. */}
                  </div>
               )
               }
            </Slide>
         }
      </div>
   )
}

export default ImageGallery

// --TODO-- resize image to load faster. 
