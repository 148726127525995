import { AppBar, Button, Toolbar } from '@material-ui/core';
import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../../assets/Green-Point-logo.png';

const DesktopHeader = () => {
   const appBarStyle = {
      background: '#FFF',
   }

   return (
      <div>
         <AppBar style={appBarStyle} position="static">
            <Toolbar>
               <Link style={{paddingRight: "20px"}} to="/">
                  <img src={logo} width="150px" alt="Green Points" />
               </Link>
               <Link to="/cai-dat">
                  <Button>
                     Báo cáo điểm xám
                  </Button>
               </Link>
            </Toolbar>
         </AppBar>
      </div>
   )
}

export default DesktopHeader
