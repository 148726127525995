import React, { useEffect, useState } from 'react';
import { axios } from '../../axios/axios';
import CustomMap from '../../map/CustomMap';
import './MobileContent.css';
import MobileHeader from './MobileHeader';

const MobileContent = () => {
   const [items, setItems] = useState([]);
   const getData = async () => {
      const resp = await axios
         .get(`/api/map/point?pointType=GRAY`)
         .catch(err => console.error(err))

      if (resp && resp.data) {
         let newItems = resp.data.data;
         setItems([...newItems]);
      }
   };


   useEffect(() => {
      getData();
   }, [])

   const navigationProps = {
      HEIGHT: 60, // 115 for navbar
      width: `${window.innerWidth}px`,
      height: '0px', // '115px' for navbar.
   }

   const contentProps = {
      width: `${window.innerWidth}px`,
      height: `${window.innerHeight - navigationProps.HEIGHT}px`,
      zoom: 5.5,
      center: {
         // Vietnam center mobile version.
         lat: 16.897884,
         lng: 106.114115
      },
   }

   return (
      <>
         <MobileHeader />
         <div className="map">
            <div className="center">
               <CustomMap
                  items={items}
                  scrollWheelZoom={true}
                  width={contentProps.width}
                  height={contentProps.height}
                  zoom={contentProps.zoom}
                  center={contentProps.center}
               />
            </div>
         </div>
         {/* WILL IMPLEMENT LATER */}
         {/* <MobileNavigation
            width={navigationProps.width}
            height={navigationProps.height}
         /> */}
      </>
   )
}

export default MobileContent

// react hook axios turorial
// https://www.youtube.com/watch?v=AirWT_XpEpM