import React, { useState } from 'react';
import icon from '../../../assets/gray-point.png';
import clickedIcon from '../../../assets/gray-point-clicked.png';

import { axios } from '../../axios/axios';
import GrayPointPopup from '../custom-popups/graypoint-popup/GrayPointPopup';
import LoadingPopup from '../custom-popups/loading-popup/LoadingPopup';

const GrayPointMarker = (props) => {
    const [isOpen, setOpen] = useState(false);
    const markerStyle = {
        cursor: 'pointer',
        position: 'absolute',
        top: '-10px',
        left: '-10px',
    };

    const onClick = (id) => {
        getDetail(id);
        setOpen(!isOpen);
    };

    const iconStyle = {
        width: '25px',
        height: '30px',
    };

    const [detail, setDetail] = useState(null);

    const getDetail = async (id) => {
        setDetail(null);
        const resp = await axios
            .get(`/api/map/point/${id}?userId=1`)
            .catch(err => console.log(err))

        if (resp && resp.data) {
            let data = resp.data.data;
            setDetail(data);
        }
    }

    return (
        <>
            {(isOpen && detail != null) &&
                <GrayPointPopup detail={detail} />
            }
            {(isOpen && (detail == null)) &&
                <LoadingPopup></LoadingPopup>
            }
            <div style={markerStyle} onClick={() => onClick(props.id)}>
                {isOpen &&
                    <img src={clickedIcon} style={iconStyle} alt="" />
                }
                {!isOpen &&
                    <img src={icon} style={iconStyle} alt="" />
                }
            </div>
        </>
    )
}

export default GrayPointMarker