import React from 'react'
import install1 from '../../assets/install/install1.png';
import install2 from '../../assets/install/install2.png';

const InstallAppPage = () => {
   const style = {
      width: '1024px',
      margin: 'auto'
   }

   return (
      <>
         <br />
         <div style={style}>
            <div>
               <img src={install1} width="1024px" alt="Green Points" />

            </div>
            <div>
               <img src={install2} width="1024px" alt="Green Points" />
            </div>

         </div>
      </>
   )
}

export default InstallAppPage
