import { useState } from 'react';
import './App.css';
import DesktopContent from './components/spartials/desktop/DesktopContent';
import MobileContent from './components/spartials/mobile/MobileContent';

function App() {
  const [platform, setPlatform] = useState(null);
  const getPlatform = () => {
    if (window.innerWidth < 600) {
      setPlatform('mobile');
    } else {
      setPlatform('desktop');
    }
  }

  return (
    <>
      <div ref={ref => getPlatform(ref)}>
        {(platform !== null && platform === 'mobile') &&
          <MobileContent></MobileContent>
        }
        {(platform !== null && platform === 'desktop') &&
          <DesktopContent></DesktopContent>
        }
      </div>
    </>
  );
}

export default App;
