import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LoadingPopup = (props) => {
   const defaultStyle = {
      position: 'absolute',
      bottom: 15,
      left: '-45px',
      width: '300px',
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 10,
      fontSize: 14,
      zIndex: 100,
      border: '1px solid transparent',
      borderRadius: '10px'
   }
   const style = props.style ? props.style : defaultStyle;
   
   return (
      <div style={style}>
         <Skeleton duration={1} style={{ height: "100px" }} />
         <Skeleton duration={1} style={{ width: "210px" }} />
         <Skeleton duration={1} style={{ width: "100px" }} />
         <Skeleton duration={1} />
      </div>
   )
}

export default LoadingPopup
