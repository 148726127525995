import { AppBar, Toolbar } from '@material-ui/core'
import React from 'react'
import logo from '../../../assets/Green-Point-logo.png'

const MobileHeader = () => {
   const appBarStyle = {
      background: '#FFF',
   }

   return (
      <div>
         <AppBar style={appBarStyle} position="static">
            <Toolbar>
               <img src={logo} width="150px" alt="Green Points" />
            </Toolbar>
         </AppBar>
      </div>
   )
}

export default MobileHeader
